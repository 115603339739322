import { CalculationDetails } from "@products/necst-ratenkredit/apis/webcalc/types";
import { AdditionalProductType, JourneyRoutes, JourneyType, OriginSystem, ProductType, SalesChannelType } from "./models/enums";
import moment from "moment";

const scenario: JourneyType = process.env.REACT_APP_SCENARIO as JourneyType;

export const getParam = (document: any, name: string) => new URL(document.location).searchParams.get(name);

export const getHashParam = (document: any, param: string) => {
  let hash = new URL((document as any).location)?.hash;
  let access_token = null;
  if (hash) {
    access_token = hash
      ?.substring(1)
      .split("&")
      ?.reduce(function (res: any, item) {
        let parts = item.split("=");
        res[parts[0]] = parts[1];
        return res;
      }, {})?.["access_token"];
  }
  return access_token;
};

export const redirectTo = (url: string) => (window.location.href = url);

export const openTabUrl = (url: string) => window.open(url, "_blank");

export const normalizeDate = (value: string) => {
  if (!value) return "";
  // it is already in the proper format
  if (value.indexOf(".") !== -1) return value;

  return value.split("-").reverse().join(".");
};

export const isEmpty = (value: any) => {
  return value === null || value === undefined;
};

export const isEmptyOrZero = (value: any) => {
  return value === null || value === undefined || value === "0" || value === 0;
};

export const isBooleanValue = (value: boolean) => {
  return value === true || value === false;
};

export const isDev = () => ["dev", "int", "cons"].includes(process.env.REACT_APP_STAGE || "");

export const buildPathWithParams = (path: string) => {
  return { pathname: path };
};

export const toBoolean = (v: any) => {
  if (typeof v === "string" || v instanceof String) {
    return v === "true";
  }
  return !!v;
};

export const isDirectAutoCredit = (product: string) => product === ProductType.directautocredit;

export const isKeycloakUrl = () => {
  const pagesWithKeycloak = [JourneyRoutes.PREFILL, JourneyRoutes.CALCULATION, JourneyRoutes.CREDIT_CHECK];
  return !!pagesWithKeycloak.find((page) => new URL(document.location as any).pathname?.includes(page));
};

export const getQuestionnaireUrl = (storefrontData: any) => storefrontData?.custom?.questionnaireUrl;

export const hasKSB = (calculation: CalculationDetails) => {
  return !!calculation.additionalProducts?.find((additionalProdct: any) => additionalProdct.additionalProductType === AdditionalProductType.KSB);
};

export const getKSBProduct = (calculation: CalculationDetails) => calculation.additionalProducts?.find((additionalProdct: any) => additionalProdct.additionalProductType === AdditionalProductType.KSB);

export const hasKSBPlus = (calculation: CalculationDetails) => {
  return !!calculation.additionalProducts?.find((additionalProdct: any) => additionalProdct.additionalProductType === AdditionalProductType.KSB_PLUS);
};

export const getKSBPlusProduct = (calculation: CalculationDetails) =>
  calculation.additionalProducts?.find((additionalProdct: any) => additionalProdct.additionalProductType === AdditionalProductType.KSB_PLUS);

export const hasSomeKSB = (calculation: CalculationDetails) => hasKSB(calculation) || hasKSBPlus(calculation);

export const isVWWA = (originSystem: string) => originSystem === OriginSystem.VWWA;

export const isEVA = (originSystem: string) => originSystem === OriginSystem.EVA;

export const isDistanceSales = (salesChannelType: SalesChannelType): boolean => salesChannelType === SalesChannelType.distancesales;

export const isPointOfSales = (salesChannelType: SalesChannelType): boolean => salesChannelType === SalesChannelType.pointofsale;

export const isOnline = (salesChannelType: SalesChannelType): boolean => salesChannelType === SalesChannelType.online;

export const getScenario = (): JourneyType => scenario;

export const isCustomer = (): boolean => scenario === JourneyType.CUSTOMER;

export const isDealer = (): boolean => scenario === JourneyType.DEALER;

export const isIdBrokerEnabled = (): boolean => process.env.REACT_APP_IDP_ENABLED === "true";

export const redirectLinkURL = (storefrontData: any) => storefrontData?.transaction?.links?.find((link: { type: string; url: string }) => link.type === "CALLBACK_STOREFRONT")?.url;

export const redirectMethod = (storefrontData: any) => {
  if (isDealer() && storefrontData?.transaction?.salesChannel?.callbackMethodName) {
    //@ts-ignore
    window[storefrontData?.transaction?.salesChannel?.callbackMethodName]();
  } else {
    redirectTo(redirectLinkURL(storefrontData));
  }
};

export const UseEvaLinkHandler = (originSystem: OriginSystem, callbackMethodName: string) => {
  //@ts-ignore
  return isEVA(originSystem) && window.showPdf && isDealer() && callbackMethodName;
};

export const handleLinkClickEva = (htmlTarget: string, htmlHref: string, e: React.MouseEvent<HTMLAnchorElement>, originSystem: OriginSystem, callbackMethodName: string) => {
  if (htmlTarget === "_blank" && UseEvaLinkHandler(originSystem, callbackMethodName)) {
    e?.preventDefault();
    const regularExpressionForAbsoluteHttpURL = /^https?:\/\//i;
    if (!regularExpressionForAbsoluteHttpURL.test(htmlHref)) {
      htmlHref = new URL(htmlHref, document.baseURI).href;
    }
    //@ts-ignore
    window.showPdf(htmlHref);
  }
};

export const monthsDifference = (startDate: any, endDate: any) => Math.abs(moment(endDate).diff(moment(startDate), "months"));

export const isENVKVNew = (vehicleData: any) => vehicleData?.odometer < 1000 || monthsDifference(vehicleData?.initialRegistrationDate, moment.now()) < 8;

export const templateWithValues = (text: any, values: any) => {
  if (typeof text !== "string") {
    return text;
  }
  let result = text;
  const regex = /\${([^}]*)}/g;
  const matchAll = text.matchAll(regex);
  // @ts-ignore
  for (const m of matchAll) {
    result = result.replace(m[0], values[m[1]]);
  }
  return result;
};

export const formatDecimal = (value: any, decimals = 1) => {
  if (value === undefined) {
    return value;
  }

  return new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
};

export const isValidNumber = (number: number) => typeof number === "number" && String(number) !== "NaN";

export const formatEuroAmount = (value: any, decimals = 2) => {
  if (value === undefined) {
    return value;
  }

  return new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
};
