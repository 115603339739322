import { StandalonePagePath } from "@product-pages/pages";
import { Analytics } from "@services/analytics";
import { store } from "@services/redux";
import { history } from "../../App";
import axios from "axios";
import { updateStorefrontData } from "@services/api/storefront/storefront.service";

const own_axios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

own_axios.interceptors.request.use((config: any) => {
  const needsAuthHeader = config.baseURL?.indexOf(process.env.REACT_APP_API_BASE_URL) === 0;

  if (!needsAuthHeader) {
    return config;
  }

  const skipAuth = store.getState().custom.data.skipAuth;

  return {
    ...config,
    headers: {
      ...config.headers,
      common: {
        ...config.headers.common,
        Authorization: skipAuth ? "skipped" : store.getState().transaction.data.token || "",
        ...(skipAuth && { "X-Skip-Auth": skipAuth }),
      },
    },
  };
});

own_axios.interceptors.response.use(
  (response) => {
    Analytics.restoreErrorInfo();
    return response;
  },
  (error) => {
    if (error.response?.status) {
      Analytics.setErrorInfo(error.config.url, error.response?.status, error.message);
    }
    if (error.response?.status === 412) {
      const storefrontData = store.getState().storefront.data;
      updateStorefrontData(error?.response?.data?.payload, storefrontData);
      history.replace(StandalonePagePath.calculationInvalid);
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);

export const FES_API = own_axios;
