import { createStore, combineReducers } from "redux";

import transactionReducer from "./features/transaction.redux";
import formReducer from "./features/form.redux";
import customReducer from "./features/custom.redux";
import documentReducer from "./features/documentUpload.redux";
import storefrontReducer from "./features/storefront.redux";
import keycloakReducer from "./features/keycloak.redux";

/* eslint-disable no-underscore-dangle */
const rootReducer = combineReducers({
  custom: customReducer,
  document: documentReducer,
  form: formReducer,
  keycloak: keycloakReducer,
  storefront: storefrontReducer,
  transaction: transactionReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());
