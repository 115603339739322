import additionalProducts from "./additionalProducts.json";
import calculation from "./calculation.json";
import chi from "./chi.json";
import contactData from "./contactData.json";
import creditCheck from "./creditCheck.json";
import customRouter from "./customRouter.json";
import documentUpload from "./documentUpload.json";
import emailConsent from "./emailConsent.json";
import emailValidation from "./emailValidation.json";
import error from "./error.json";
import expired from "./expired.json";
import legitimation from "./legitimation.json";
import login from "./login.json";
import financialData from "./financialData.json";
import form from "./form.json";
import marketingConsent from "./marketingConsent.json";
import paymentData from "./paymentData.json";
import personalData from "./personalData.json";
import postIdent from "./postIdent.json";
import privacyConsent from "./privacyConsent.json";
import professionalData from "./professionalData.json";
import shoppingCart from "./shoppingCart.json";
import spinnerModal from "./spinnerModal.json";
import summary from "./summary.json";
import wizard from "./wizard.json";
import denied from "./denied.json";
import modalContact from "./modalContact.json";
import modalContinueOnline from "./modalContinueOnline.json";
import onlineSelection from "./onlineSelection.json";

export const i18n = {
  additionalProducts,
  calculation,
  chi,
  contactData,
  creditCheck,
  customRouter,
  documentUpload,
  emailConsent,
  modalContact,
  modalContinueOnline,
  emailValidation,
  error,
  expired,
  financialData,
  form,
  legitimation,
  login,
  marketingConsent,
  onlineSelection,
  paymentData,
  personalData,
  postIdent,
  privacyConsent,
  professionalData,
  shoppingCart,
  spinnerModal,
  summary,
  wizard,
  denied,
};
