export const SET_SHOW_CONTINUELATER = "custom//SET_SHOW_CONTINUELATER";
export const SET_HASDACERROR = "custom//SET_HASDACERROR";
export const SET_LEGITIMATION_URL = "custom//SET_LEGITIMATION_URL";
export const SET_LEGITIMATION_PROCESS_ID = "custom//SET_LEGITIMATION_PROCESS_ID";
export const SET_SHOW_EMAIL_VALIDATION = "custom//SET_SHOW_EMAIL_VALIDATION";
export const SET_SHOW_CREDIT_CHECK_MODAL = "custom//SET_SHOW_CREDIT_CHECK_MODAL";
export const SET_IS_EMAIL_VALIDATED = "custom//SET_IS_EMAIL_VALIDATED";
export const SET_IS_TLS_SUPPORTED = "custom//SET_IS_TLS_SUPPORTED";
export const SET_SKIP_AUTH = "custom//SET_SKIP_AUTH";
export const SET_ISLOADING = "custom//SET_ISLOADING";
interface SetShowContinueLater {
  type: typeof SET_SHOW_CONTINUELATER;
  payload: any;
}

interface SetHasDacError {
  type: typeof SET_HASDACERROR;
  payload: any;
}

interface setLegitimationUrl {
  type: typeof SET_LEGITIMATION_URL;
  payload: any;
}

interface setLegitimationProcessId {
  type: typeof SET_LEGITIMATION_PROCESS_ID;
  payload: any;
}

interface setShowEmailvalidation {
  type: typeof SET_SHOW_EMAIL_VALIDATION;
  payload: any;
}

interface setShowCreditcheckModal {
  type: typeof SET_SHOW_CREDIT_CHECK_MODAL;
  payload: any;
}
interface setIsTlsSupported {
  type: typeof SET_IS_TLS_SUPPORTED;
  payload: any;
}
interface setSkipAuth {
  type: typeof SET_SKIP_AUTH;
  payload: any;
}
interface setIsLoading {
  type: typeof SET_ISLOADING;
  payload: boolean;
}

export type CustomActions = SetShowContinueLater | SetHasDacError | setLegitimationUrl | setLegitimationProcessId | setShowEmailvalidation | setShowCreditcheckModal | setIsTlsSupported | setSkipAuth | setIsLoading;
