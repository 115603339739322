import { ContentSection, Main } from "@vwfs-bronson/bronson-react";
import PageHeader, { PageHeaderProps } from "./PageHeader";
import PageFooter from "./PageFooter";
import { DEFAULT_PAGE_WRAP_SIZE } from "@product-pages/common/types";

interface Props extends PageHeaderProps {
  pageWrapSize?: "medium" | "small" | "xsmall" | "xxsmall";
  contentSectionClassName?: string; // Defaults to u-pt
  children?: React.ReactNode;
}

export const CommonPage: React.FC<Props> = ({ children, pageWrapSize, contentSectionClassName, ...pageHeaderProps }) => (
  <>
    <PageHeader {...pageHeaderProps} />

    <Main>
      <ContentSection pageWrapSize={pageWrapSize ?? DEFAULT_PAGE_WRAP_SIZE} className={contentSectionClassName ?? "u-pt"}>
        <ContentSection.ComponentWrapper>{children}</ContentSection.ComponentWrapper>
      </ContentSection>
    </Main>

    <PageFooter />
  </>
);
