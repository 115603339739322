import { importRetry } from "@common/import-retry";
import { Product } from "@product-pages/common/types";
import React from "react";

// https://confluence.platform.vwfs.io/display/SPR/NeCSt+%7C+Direct+Credit
// https://confluence.platform.vwfs.io/display/SPR/DC+-+Content+Sheets

export const NECST_DAC: Product = {
  productPath: "",
  productId: "DAC",
  component: React.lazy(() => importRetry(() => import("./AutokreditPages"))),
};
