import { FES_API } from "@apis/common";
import * as actions from "../../redux/features/storefront.redux";
import { store } from "../../redux";
import * as documentsActions from "../../../services/redux/features/documentUpload.redux";
import * as customActions from "../../../services/redux/features/custom.redux";
import * as storefrontActions from "../../../services/redux/features/storefront.redux";
import * as transactionActions from "../../../services/redux/features/transaction.redux";
import { pushStorefrontDataToDatalayer } from "@services/analytics/storefront";
import { isCustomer } from "@common/utils";

export const getStorefrontData = (props: any) => {
  const { transactionId, tan, token } = props;
  const url = `/application/${transactionId}/${isCustomer() ? "customer" : "dealer"}/prefill`;

  let body = {};
  if (isCustomer()) {
    if (token) {
      body = { token };
    } else if (tan) {
      body = { tan };
    }
  }

  return FES_API.post(url, body).then((response) => {
    return response.data;
  });
};
export const loadStorefrontDataToRedux = (props: any): any => {
  store.dispatch(actions.storeId(props?.transactionId));
  return internalLoadStorefrontDataToRedux(props);
};

const internalLoadStorefrontDataToRedux = (props: any) => {
  store.dispatch(actions.pending());
  return getStorefrontData(props)
    .then((response) => {
      pushStorefrontDataToDatalayer(response.data);
      store.dispatch(actions.initial(response.data));
      // Store token only for CUSTOMER scenario
      if (response?.token && isCustomer()) {
        store.dispatch(transactionActions.setToken(response?.token));
      }
      store.dispatch(transactionActions.saveExpiryDate(response?.data?.transaction?.expiryDate));
      store.dispatch(customActions.setLegitimationUrl(response.data?.transaction?.links?.find((link: any) => link.type === "LEGITIMATION")?.url));
      store.dispatch(customActions.setLegitimationProcessId(response.data?.custom?.legitimationProcessId));
      if (response?.data?.custom?.documents) {
        store.dispatch(documentsActions.getDocuments({ documents: response.data.custom.documents }));
      }
      if (response.files) {
        store.dispatch(documentsActions.setFiles(response.files));
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateStorefrontData = (data: any, storefrontData = {}) => {
  const { custom, customerData, vehicleData, financialProduct, transaction, dealerData, additionalProducts } = data;
  const dataToStore = {
    ...storefrontData,
    ...(custom && { custom }),
    ...(customerData && { customerData }),
    ...(vehicleData && { vehicleData }),
    ...(financialProduct && { financialProduct }),
    ...(transaction && { transaction }),
    ...(dealerData && { dealerData }),
    ...(additionalProducts && { additionalProducts }),
  };
  store.dispatch(storefrontActions.done(dataToStore));
  pushStorefrontDataToDatalayer(dataToStore);
};

export const checkIfEmailChanged = (email: string) => {
  const storefrontData = store.getState().storefront.data;
  if (email !== storefrontData?.customerData?.contactData?.email) {
    updateStorefrontData(
      {
        customerData: {
          ...storefrontData?.customerData,
          contactData: {
            ...storefrontData?.customerData?.contactData,
            email,
          },
        },
        custom: {
          ...storefrontData?.custom,
          isEmailValidated: false,
        },
      },
      storefrontData
    );
  }
};
