import * as React from "react";
import { ToastNotification, ToastNotifications, Logo } from "@vwfs-bronson/bronson-react";
import classNames from "classnames";
import "./PageHeader.css";
import { getKeycloakInitialized, getKeycloakIsLogged } from "@services/redux/features/keycloak.redux";
import { connect } from "react-redux";
import { logOut } from "@services/api/login";
import { useTranslation } from "react-i18next";
import useKeycloakConditonal from "@common/hooks/useKeycloakConditional";
import { Brand } from "@common/models/enums";

export interface PageHeaderProps {
  documentTitle?: string;
  showHeroTeaser?: boolean;
  heroTeaserImage?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

export interface PageHeaderReduxProps extends PageHeaderProps {
  isLoggedIn: boolean;
  keycloakInitialized: boolean;
}

const PageHeader = (props: PageHeaderReduxProps) => {
  const { t } = useTranslation();
  const [logoutSuccessful, setLogoutSuccessful] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const { documentTitle, isLoggedIn, keycloakInitialized, title } = props;
  const { keycloak } = useKeycloakConditonal(keycloakInitialized);

  const docTitle = documentTitle ? documentTitle : title;
  const headerTitle = title;
  const isHeaderStatic = true;

  const brand = process.env.REACT_APP_BRAND;

  React.useEffect(() => {
    document.title = `${docTitle ? `${docTitle} - ` : ""}Volkswagen Bank GmbH`;
  }, [docTitle]);

  let removeNotification: any;
  const handleShowNotification = (value: boolean) => {
    setLogoutSuccessful(value);
    setShowNotification(true);
    removeNotification = setTimeout(() => setShowNotification(false), 5000);
  };

  const setLoggedOut = async (e: any) => {
    e.preventDefault();

    if (keycloak) {
      try {
        await logOut({
          token: keycloak.token,
          clientId: keycloak.clientId,
          clientSecret: keycloak.clientSecret,
          refreshToken: keycloak.refreshToken,
        });
        keycloak?.clearToken();
        handleShowNotification(true);
      } catch (error) {
        handleShowNotification(false);
      }
    }
  };

  return (
    <>
      {showNotification && (
        <ToastNotifications>
          <ToastNotification
            onClose={() => {
              setShowNotification(false);
              clearTimeout(removeNotification);
            }}
            {...(logoutSuccessful ? { success: true } : { error: true })}
          >
            {t("login:logoutSuccess")}
          </ToastNotification>
        </ToastNotifications>
      )}
      <div className="b1-page-header">
        <header className="c-header__wrapper">
          <div
            className={classNames("c-header", {
              "c-header--static": isHeaderStatic,
            })}
          >
            <div className="c-header__content-wrapper">
              <div className="c-header__logo">
                <Logo {...(brand === Brand.vwn && { specificLogo: "commercial-vehicle-de" })} />
              </div>

              {(title || isLoggedIn) && (
                <div className="c-context-bar">
                  <div className="c-context-bar__panel" aria-hidden="true">
                    <nav className="c-context-bar__navs" aria-label="primary">
                      {title && (
                        <ul className="c-context-bar__nav">
                          <li>
                            <span className="c-context-bar__title">{headerTitle}</span>
                          </li>
                        </ul>
                      )}
                      {isLoggedIn && (
                        <ul className="c-context-bar__nav">
                          <li>
                            <a className="c-context-bar__link" href="#/" onClick={setLoggedOut} rel="noopener noreferrer">
                              <span>{t("login:logout")}</span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>

        <div className="o-page-wrap b1-header-title">
          <h1>{headerTitle}</h1>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: getKeycloakIsLogged(state),
    keycloakInitialized: getKeycloakInitialized(state),
  };
};
export default connect<{}, {}, PageHeaderProps>(mapStateToProps)(PageHeader);
