import React from "react";
import { importRetry } from "@common/import-retry";
import { StandalonePage } from "@product-pages/common/types";
import { StandalonePagePath } from "./standalone-page-path";
import { ErrorPage } from "./ErrorPage";
import { LoadingPage } from "./LoadingPage";
import ErrorOptionPage from "./ErrorOptionPage";
import { ExpiredLinkPage } from "./ExpiredLinkPage";
import { ErrorCustomerService } from "./ErrorCustomerService";
import { ErrorAccessDeniedPage } from "./ErrorAccessDenied";
import CalculationInvalid from "./CalculationInvalid";
import ErrorDocumentsPage from "./ErrorDocuments";

const LicensesPage = React.lazy(() => importRetry(() => import("../../product-independent-pages/pages/LicensesPage")));
export const DEFAULT_STANDALONE_PAGES: StandalonePage[] = [
  {
    path: StandalonePagePath.calculationInvalid,
    component: CalculationInvalid,
  },
  {
    path: StandalonePagePath.licenses,
    component: LicensesPage,
  },
  {
    path: StandalonePagePath.error,
    component: ErrorPage,
  },
  {
    path: StandalonePagePath.errorOption,
    component: ErrorOptionPage,
  },
  {
    path: StandalonePagePath.expiredLink,
    component: ExpiredLinkPage,
  },
  {
    path: StandalonePagePath.loading,
    component: LoadingPage,
  },
  {
    path: StandalonePagePath.errorCustomerService,
    component: ErrorCustomerService,
  },
  {
    path: StandalonePagePath.accessDenied,
    component: ErrorAccessDeniedPage,
  },
  {
    path: StandalonePagePath.errorDocument,
    component: ErrorDocumentsPage,
  },
];
