import { CustomState } from "../models/CustomState";
import {
  CustomActions,
  SET_HASDACERROR,
  SET_SHOW_CONTINUELATER,
  SET_LEGITIMATION_URL,
  SET_SHOW_EMAIL_VALIDATION,
  SET_IS_TLS_SUPPORTED,
  SET_SKIP_AUTH,
  SET_SHOW_CREDIT_CHECK_MODAL,
  SET_LEGITIMATION_PROCESS_ID,
  SET_ISLOADING,
} from "./actions/custom.actions";

export function setShowContinueLater(data: any): CustomActions {
  return {
    type: SET_SHOW_CONTINUELATER,
    payload: data,
  };
}

export function getShowContinueLater(store: any) {
  return store.custom.data.showContinueLater;
}

export function setHasDacError(data: any): CustomActions {
  return {
    type: SET_HASDACERROR,
    payload: data,
  };
}

export function getHasDacError(store: any) {
  return store.custom.data.hasDacError;
}

export function setLegitimationUrl(data: any): CustomActions {
  return {
    type: SET_LEGITIMATION_URL,
    payload: data,
  };
}

export function getLegitimationUrl(store: any) {
  return store.custom.data.legitimationUrl;
}

export function setLegitimationProcessId(data: any): CustomActions {
  return {
    type: SET_LEGITIMATION_PROCESS_ID,
    payload: data,
  };
}

export function getLegitimationProcessId(store: any) {
  return store.custom.data.legitimationProcessId;
}

export function getShowEmailValidation(store: any) {
  return store.custom.data.showEmailValidationModal;
}

export function setShowEmailvalidation(data: any): CustomActions {
  return {
    type: SET_SHOW_EMAIL_VALIDATION,
    payload: data,
  };
}

export function getShowCreditCheckModal(store: any) {
  return store.custom.data.showCreditCheckModal;
}

export function setShowCreditCheckModal(data: any): CustomActions {
  return {
    type: SET_SHOW_CREDIT_CHECK_MODAL,
    payload: data,
  };
}

export function getIsTlsSupported(store: any) {
  return store.custom.data.isTlsSupported;
}

export function setIsTlsSupported(data: any): CustomActions {
  return {
    type: SET_IS_TLS_SUPPORTED,
    payload: data,
  };
}

export function shouldSkipAuth(store: any) {
  return store.custom.data.skipAuth;
}

export function setSkipAuth(data: any): CustomActions {
  return {
    type: SET_SKIP_AUTH,
    payload: data,
  };
}

export function setIsLoading(data: boolean): CustomActions {
  return {
    type: SET_ISLOADING,
    payload: data,
  };
}

const initialState: CustomState = {
  data: {
    showContinueLater: false,
    hasDacError: false,
    legitimationUrl: undefined,
    legitimationProcessId: undefined,
    showEmailValidationModal: false,
    showCreditCheckModal: false,
    isTlsSupported: false,
    skipAuth: false,
    isLoading: false,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_SHOW_CONTINUELATER:
      return {
        ...state,
        data: {
          ...state.data,
          showContinueLater: action.payload,
        },
      };
    case SET_HASDACERROR:
      return {
        ...state,
        data: {
          ...state.data,
          hasDacError: action.payload,
        },
      };
    case SET_LEGITIMATION_URL:
      return {
        ...state,
        data: {
          ...state.data,
          legitimationUrl: action.payload,
        },
      };
    case SET_LEGITIMATION_PROCESS_ID:
      return {
        ...state,
        data: {
          ...state.data,
          legitimationProcessId: action.payload,
        },
      };
    case SET_SHOW_EMAIL_VALIDATION:
      return {
        ...state,
        data: {
          ...state.data,
          showEmailValidationModal: action.payload,
        },
      };
    case SET_SHOW_CREDIT_CHECK_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          showCreditCheckModal: action.payload,
        },
      };
    case SET_IS_TLS_SUPPORTED:
      return {
        ...state,
        data: {
          ...state.data,
          isTlsSupported: action.payload,
        },
      };
    case SET_SKIP_AUTH:
      return {
        ...state,
        data: {
          ...state.data,
          skipAuth: action.payload,
        },
      };
    case SET_ISLOADING:
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: action.payload,
        },
      };
    default:
      return state;
  }
}
