import { Analytics } from ".";

export const pushStorefrontDataToDatalayer = (storefrontData) => {
  return Analytics.addToDatalayer({
    "core.attributes.transactionID": storefrontData?.transaction?.id || storefrontData?.transactionId,
    "product[0].attributes.duration": storefrontData.financialProduct?.calculation?.duration,
    "product[0].attributes.durationUnit": storefrontData.financialProduct?.calculation?.durationUnit,
    "product[0].attributes.yearlyMileage": storefrontData.financialProduct?.calculation?.yearlyMileage,
    "product[0].attributes.excessMileage": storefrontData.financialProduct?.excessMileagePriceAmount,
    "product[0].attributes.mileageUnit": storefrontData.financialProduct?.calculation?.mileageUnit,
    "product[0].attributes.netLoanAmount": storefrontData.financialProduct?.calculation?.netLoanAmount?.amount,
    "product[0].attributes.grossLoanAmount": storefrontData.financialProduct?.calculation?.grossLoanAmount?.amount,
    "product[0].attributes.downPaymentAmount": storefrontData.financialProduct?.calculation?.downPaymentAmount?.amount,
    "product[0].attributes.nominalInterestRate": storefrontData.financialProduct?.calculation?.nominalInterestRate,
    "product[0].attributes.effectiveInterestRate": storefrontData.financialProduct?.calculation?.effectiveInterestRate,
    "product[0].attributes.balloonPayment": storefrontData.financialProduct?.calculation?.balloonPayment,
    "product[0].attributes.nominalInterestAmount": storefrontData.financialProduct?.calculation?.nominalInterestAmount,
    "product[0].vehicleModel[0].manufacturer": storefrontData.vehicleData?.model?.manufacturer,
    "product[0].vehicleModel[0].manufacturerCode": storefrontData.vehicleData?.model?.manufacturerCode,
    "product[0].vehicleModel[0].name": storefrontData.vehicleData?.model.name,
    "product[0].vehicleModel[0].year": storefrontData.vehicleData?.model.year,
    "product[0].vehicleModel[0].descriptionLong": storefrontData.vehicleData?.model?.descriptionLong,
    "product[0].vehicleModel[0].initialRegistrationDate": storefrontData.vehicleData?.initialRegistrationDate,
    "product[0].vehicleModel[0].typeCode": storefrontData.vehicleData?.model?.typeCode,
    "product[0].vehicleModel[0].colorExterior": storefrontData.vehicleData?.model?.colorExterior,
    "product[0].vehicleModel[0].engine.fuelType": storefrontData.vehicleData?.model?.engine?.fuelType,
    "product[0].vehicleModel[0].engine.enginePower": storefrontData.vehicleData?.model?.engine?.power,
    "product[0].customerData.yearOfBirth": storefrontData.birthDate,
    "product[0].customerData.address.zipCode": storefrontData.postcode,
    "product[0].vehicleModel[0].currentMileage": storefrontData.vehicleData?.odometer,
    "product[0].vehicleModel[0].currentMileageUnit": storefrontData.vehicleData?.odometerUnit,
    ...(storefrontData?.dealerData?.companyId && { "dealerData.companyId": storefrontData?.dealerData?.companyId }),
    ...(storefrontData?.dealerData?.companyName && { "dealerData.companyName": storefrontData?.dealerData?.companyName }),
    ...(storefrontData?.dealerData?.regionId && { "dealerData.regionId": storefrontData?.dealerData?.regionId }),
    ...(storefrontData?.dealerData?.address?.street && { "dealerData.address.street": storefrontData?.dealerData?.address?.street }),
    ...(storefrontData?.dealerData?.address?.zipCode && { "dealerData.address.zipCode": storefrontData?.dealerData?.address?.zipCode }),
    ...(storefrontData?.dealerData?.address?.city && { "dealerData.address.city": storefrontData?.dealerData?.address?.city }),
    ...(storefrontData?.salesChannel?.originSystem && { "core.attributes.context": storefrontData?.salesChannel?.originSystem }),
  });
};
