import React, { ComponentType, LazyExoticComponent } from "react";
import { WizardFormData } from "@product-pages/model/types";
import { Stage } from "@common/types";

export const DEFAULT_PAGE_WRAP_SIZE = "medium";

export interface Product {
  productPath: string;
  productId: string;
  component: LazyExoticComponent<ComponentType>;
}

export interface MaintenanceConfig {
  start: string;
  end: string;
  title: string;
  message?: string;
  stages: [Stage, ...Stage[]]; // At least one stage is required
}

export type ProductConfig = Omit<Product, "component"> & {
  documentTitle: string;
  title: React.ReactNode;
  phoneNumber: { url: string; text: string };
  maintenance?: MaintenanceConfig;
};

export type WizardPageSubmitResult =
  | { action: "gotoNextStep"; state?: unknown }
  | { action: "gotoStep"; stepPath: string; state?: unknown }
  | { action: "gotoStandalonePage"; pagePath: string; state?: unknown }
  | { action: "gotoExternalURL"; externalURL: string };

export interface WizardPageProps {
  stepIndex: number;
  title: string;
  path: string;
  pageWrapSize?: "medium" | "small" | "xsmall" | "xxsmall";
  validate?: () => Promise<string | undefined>;
  onSubmit?: {
    handler: () => Promise<WizardPageSubmitResult>;
    spinnerText?: string;
  };
  backNavigationAllowed?: boolean;
  showShoppingCart?: boolean;
  indexable?: boolean;
}

export interface StandalonePage {
  path: string;
  component: React.ComponentType;
}

export interface FeatureConfig {
  readonly isValidationEnabled: boolean;
}

export interface WizardPageContextType {
  previousStepPath?: string;
  submitPage: () => void;
  submitError?: string;
}

export interface WizardInitData<WFD extends WizardFormData> {
  featureConfig: FeatureConfig;
  initialFormData: WFD;
}

export interface TitleText {
  title: string;
  text: string;
}
