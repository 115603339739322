import * as React from "react";
import { BrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Modal } from "@vwfs-bronson/bronson-react";
import { useTranslation } from "react-i18next";

type Props = {
  basename?: string;
  children: React.ReactNode;
  history: BrowserHistory;
};

export const CustomRouter = ({ basename, children, history }: Props) => {
  const [show, setShow] = React.useState(false);
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });
  const { t } = useTranslation();

  const isFootnote = (hash: string) => hash?.includes("#footnote");

  React.useLayoutEffect(() => {
    if (history) {
      history.listen((props) => {
        const { action, location } = props;
        console.log("location");
        if (action === "POP") {
          history.go(1);
          if (!isFootnote(location?.hash)) {
            setShow(true);
            window.scrollTo(0, 0);
          }
        } else {
          setState(props);
        }
      });
    }
  }, [history]);

  return (
    <>
      <Modal
        title={t("customRouter:navigationBlockModal:title")}
        onClose={() => {
          setShow(false);
        }}
        buttonConfirmText={t("customRouter:navigationBlockModal:button")}
        onConfirm={() => {
          setShow(false);
        }}
        shown={show}
        center
      >
        {t("customRouter:navigationBlockModal:text")}
      </Modal>
      <Router basename={basename} location={state.location} navigator={history} navigationType={state.action}>
        {children}
      </Router>
    </>
  );
};
