import { BackToTop, Footer } from "@vwfs-bronson/bronson-react";
import { Footnotes } from "./Footnotes";
import CHI, { CHIpaths } from "./CustomerHappinessIndex/CHI";
import { getStorefrontData } from "@services/redux/features/storefront.redux";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { JourneyRoutes, SalesChannelType } from "@common/models/enums";
import { handleLinkClickEva, isDealer } from "@common/utils";

interface PageFooterReduxProps {
  storefrontData: any;
}

const PageFooter: React.FC<PageFooterReduxProps> = ({ storefrontData }) => {
  const { pathname } = useLocation();
  const splittedPath = pathname.split("/")?.pop();
  const showCHI = () => !isDealer() && storefrontData?.transaction?.salesChannel?.salesChannelType === SalesChannelType.online && CHIpaths.includes(splittedPath as JourneyRoutes);

  const year = new Date().getFullYear();
  return (
    <>
      {showCHI() && <CHI />}
      <BackToTop>Nach oben</BackToTop>
      <Footer>
        <Footer.ModuleBreadcrumbs />
        <Footer.ModuleMetaNav copyrightText={`Volkswagen Bank GmbH ${year}`}>
          <Footer.ModuleMetaNav.Item
            href="https://www.vwfs.de/service/preise-konditionen.html"
            normalLink={true}
            linkProps={{ target: "_blank" }}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              handleLinkClickEva(
                "_blank",
                "https://www.vwfs.de/service/preise-konditionen.html",
                e,
                storefrontData?.transaction?.salesChannel?.originSystem,
                storefrontData?.transaction?.salesChannel?.callbackMethodName
              )
            }
          >
            Preise &amp; Konditionen
          </Footer.ModuleMetaNav.Item>
          <Footer.ModuleMetaNav.Item
            href="https://www.vwfs.de/service/impressum.html"
            normalLink={true}
            linkProps={{ target: "_blank" }}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              handleLinkClickEva(
                "_blank",
                "https://www.vwfs.de/service/impressum.html",
                e,
                storefrontData?.transaction?.salesChannel?.originSystem,
                storefrontData?.transaction?.salesChannel?.callbackMethodName
              )
            }
          >
            Impressum
          </Footer.ModuleMetaNav.Item>
          <Footer.ModuleMetaNav.Item
            href="https://www.vwfs.de/service/rechtliches/datenschutz.html"
            normalLink={true}
            linkProps={{ target: "_blank" }}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              handleLinkClickEva(
                "_blank",
                "https://www.vwfs.de/service/rechtliches/datenschutz.html",
                e,
                storefrontData?.transaction?.salesChannel?.originSystem,
                storefrontData?.transaction?.salesChannel?.callbackMethodName
              )
            }
          >
            Datenschutz
          </Footer.ModuleMetaNav.Item>
          <Footer.ModuleMetaNav.Item
            href="/licenses"
            linkProps={{ target: "_blank" }}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              handleLinkClickEva("_blank", "/licenses", e, storefrontData?.transaction?.salesChannel?.originSystem, storefrontData?.transaction?.salesChannel?.callbackMethodName)
            }
          >
            Lizenzhinweise Dritter
          </Footer.ModuleMetaNav.Item>
          <Footer.ModuleMetaNav.Item
            href="https://www.vwfs.de/"
            normalLink={true}
            linkProps={{ target: "_blank" }}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              handleLinkClickEva("_blank", "https://www.vwfs.de/", e, storefrontData?.transaction?.salesChannel?.originSystem, storefrontData?.transaction?.salesChannel?.callbackMethodName)
            }
          >
            Volkswagen Financial Services AG
          </Footer.ModuleMetaNav.Item>
        </Footer.ModuleMetaNav>
        <Footer.ModuleFootnotes>
          <Footnotes />
        </Footer.ModuleFootnotes>
      </Footer>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    storefrontData: getStorefrontData(state),
  };
};
export default connect(mapStateToProps)(PageFooter);
