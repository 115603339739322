export enum JourneyRoutes {
  PREFILL = "prefill",
  CALCULATION = "calculation",
  CREDIT_CHECK = "credit-check",
  CREDIT_CHECK_SUCCESS = "credit-check-success",
  CREDIT_CHECK_REFUSED = "credit-check-refused",
  CREDIT_CHECK_MANUAL = "credit-check-manual",
  OFFLINE_CONFIRM = "offline-confirm",
  POST_IDENT = "post-ident",
  DOCUMENT_UPLOAD = "document-upload",
  DOCUMENT_UPLOAD_SUCCESS = "document-upload-success",
  DOCUMENT_REUPLOAD_SUCCESS = "document-reupload-success",
  DEALER_LEGITIMATION = "dealer-legitimation",
  LEGITIMATION_SUCCESS = "legitimation-success",
  LEGITIMATION_ERROR = "legitimation-error",
  TEMPORARY_ERROR = "temporary-error",
  LEGITIMATION_MISMATCH = "legitimation-mismatch",
  PAPER_SIGNATURE_ENTRY = "paper-signature-entry",
  PAPER_SIGNATURE_SUCCESS = "paper-signature-success",
  SIGNATURE_FAILED = "signature-failed",
  SIGNATURE_SUCCESS = "signature-success",
  CONTRACT_CONFIRMATION = "contract-confirmation",
}

export enum JourneyStatus {
  INITIALIZED = "INITIALIZED",
  CALCULATED = "CALCULATED",
  CALCULATION_INVALID = "CALCULATION_INVALID",
  APPLICATIONFORM_STARTED = "APPLICATIONFORM_STARTED",
  APPLICATIONFORM_COMPLETED = "APPLICATIONFORM_COMPLETED",
  BACKEND_HANDOVER_COMPLETED = "BACKEND_HANDOVER_COMPLETED",
  BACKEND_HANDOVER_FAILED = "BACKEND_HANDOVER_FAILED",
  CREDIT_APPROVED = "CREDIT_APPROVED",
  CREDIT_REFUSED = "CREDIT_REFUSED",
  CUSTOMER_UPLOAD = "CUSTOMER_UPLOAD",
  DOCUMENTS_UPLOAD = "DOCUMENTS_UPLOAD",
  DOCUMENTS_REUPLOAD = "DOCUMENTS_REUPLOAD",
  DOCUMENTS_UPLOADED = "DOCUMENTS_UPLOADED",
  DOCUMENTS_REUPLOADED = "DOCUMENTS_REUPLOADED",
  LEGITIMATION_REFUSED = "LEGITIMATION_REFUSED",
  LEGITIMATION_SUCCESSFUL_DATA_INCONSISTENT = "LEGITIMATION_SUCCESSFUL_DATA_INCONSISTENT",
  LEGITIMATION_SUCCESSFUL_DATA_MATCHED = "LEGITIMATION_SUCCESSFUL_DATA_MATCHED",
  CONTRACT_INTERRUPTED = "CONTRACT_INTERRUPTED",
  CONTRACT_PENDING = "CONTRACT_PENDING",
  CONTRACT_SIGNED = "CONTRACT_SIGNED",
  CONTRACT_PRINTED = "CONTRACT_PRINTED",
  CANCELED = "CANCELED",
}

export enum MismatchProcessStatus {
  IN_PROGRESS = "IN_PROGRESS",
  ERROR = "ERROR",
  SUCCESS_APPROVED = "SUCCESS_APPROVED",
  SUCCESS_REFUSED = "SUCCESS_REFUSED",
  SUCCESS_MANUAL = "SUCCESS_MANUAL",
}

export enum ProductType {
  directcredit = "directcredit",
  directautocredit = "directautocredit",
}

export enum DocumentsGenerationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum Role {
  PRIVATE = "PRIVATE",
  VWGROUP_STAFF = "VWGROUP_STAFF",
}

export enum AdditionalProductType {
  KSB = "CREDIT_PROTECTION_INSURANCE",
  KSB_PLUS = "CPIPREMIUM",
}

export enum OriginSystem {
  VWWA = "VWWA",
  EVA = "EVA",
}

export enum JourneyType {
  CUSTOMER = "CUSTOMER",
  DEALER = "DEALER",
}

export enum ContinueOptions {
  NONE = "NONE",
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export enum SalesChannelType {
  distancesales = "distancesales",
  pointofsale = "pointofsale",
  online = "online",
}

export enum MimeType {
  SVG = "image/svg+xml",
  PDF = "application/pdf",
}

export enum EngineType {
  INTERNAL_COMBUSTION_ENGINE = "INTERNAL_COMBUSTION_ENGINE",
  EXTERNALLY_CHARGEABLE_HYBRID_ELECTRIC_VEHICLE = "EXTERNALLY_CHARGEABLE_HYBRID_ELECTRIC_VEHICLE",
  NOT_EXTERNALLY_CHARGEABLE_HYBRID_ELECTRIC_VEHICLE = "NOT_EXTERNALLY_CHARGEABLE_HYBRID_ELECTRIC_VEHICLE",
  PURE_ELECTRIC_VEHICLE = "PURE_ELECTRIC_VEHICLE",
}

export enum EnergyManagementType {
  UNESPECIFIED = "UNESPECIFIED",
  PURE = "PURE",
  SUSTAINING = "SUSTAINING",
  WEIGHTED = "WEIGHTED",
}

export enum FuelType {
  GASOLINE = "GASOLINE",
  ELECTRICITY = "ELECTRICITY",
  DIESEL = "DIESEL",
  COMPRESSED_NATURAL_GAS = "COMPRESSED_NATURAL_GAS",
}

export enum Brand {
  audi = "audi",
  seat = "seat",
  skoda = "skoda",
  vw = "vw",
  vwfs = "vwfs",
  vwn = "vwn",
}
